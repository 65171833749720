.sale-wrap {
  position: relative;
  background-color: $accent;
  padding: 59px 0 68px 0;
  margin-bottom: 100px;
  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }
  @media (max-width: 991px) {
    padding: 48px 0 57px 0;
  }
  @media (max-width: 768px) {
    padding: 499px 0 57px 0;
  }
  @media (max-width: 576px) {
    padding: 375px 0 57px 0;
    margin-bottom: 70px;
  }
}
.sale-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100%;
    height: 499px;
  }
  @media (max-width: 576px) {
    height: 375px;
  }
}
.sale {
  @include flex(row, flex-end, center);
  @media (max-width: 768px) {
    @include flex(column, center, center);
  }
  &__content {
    width: 510px;
    @media (max-width: 991px) {
      width: 305px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    h3 {
      color: $white;
      text-align: center;
      font-size: 45px;
      font-weight: 300;
      line-height: 130%;
      text-transform: uppercase;
      @media (max-width: 1200px) {
        font-size: 40px;
      }
      @media (max-width: 991px) {
        font-size: 30px;
      }
      @media (max-width: 768px) {
        margin-top: 42px;
        margin-bottom: 5px;
      }
    }
    h4 {
      color: $white;
      text-align: center;
      text-shadow: 0px 4px 15px rgba(54, 33, 86, 0.2);
      font-size: 70px;
      font-weight: 700;
      @media (max-width: 1200px) {
        font-size: 65px;
      }
      @media (max-width: 991px) {
        font-size: 60px;
      }
      @media (max-width: 768px) {
        margin-bottom: 5px;
      }
    }
    h5 {
      color: $white;
      text-align: center;
      font-size: 49px;
      font-weight: 600;
      line-height: 130%;
      text-transform: uppercase;
      margin-bottom: 26px;
      @media (max-width: 1200px) {
        font-size: 44px;
      }
      @media (max-width: 991px) {
        font-size: 33px;
        margin-bottom: 21px;
      }
    }
    button {
      margin: 0 auto;
    }
  }
}
