.container {
  width: 1200px;
  margin: auto;
  padding: 0 15px;
  @media (max-width: 1200px) {
    width: 991px;
  }
  @media (max-width: 991px) {
    width: 768px;
  }
  @media (max-width: 768px) {
    width: 576px;
  }
  @media (max-width: 576px) {
    width: 375px;
  }
  @media (max-width: 375px) {
    width: 100%;
  }
}
