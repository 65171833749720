/* Скидання стилів */

/* Скидання маргінів та відступів */
html,
body,
ul,
ol,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

/* Скидання списків */
ul,
ol {
  list-style: none;
}

/* Скидання гіперпосилань */
a {
  text-decoration: none;
  color: inherit;
}

/* Скидання форм */
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font-size: 100%;
  vertical-align: baseline;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
}

/* Скидання розмірів зображень */
img {
  max-width: 100%;
  height: auto;
}

/* Скидання вирівнювання вертикальних форм елементів */
input[type="checkbox"],
input[type="radio"],
input[type="file"] {
  vertical-align: middle;
}
