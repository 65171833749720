.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  @include flex(row, center, center);
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba($color: $text, $alpha: 0.4);
  @include transition;
  padding: 0 20px;
  z-index: 999;
}

.modal {
  position: relative;
  @include flex(column, center, center);
  padding: 71px 148px 71px 115px;
  border-radius: 5px;
  background: $white;
  box-shadow: $shadow;
  @media (max-width: 991px) {
    padding: 71px 100px;
  }
  @media (max-width: 768px) {
    padding: 40px 35px 35px 35px;
  }
  &__close {
    position: absolute;
    top: 51px;
    right: 52px;
    cursor: pointer;
    @media (max-width: 768px) {
      top: 20px;
      right: 20px;
    }
  }
  h2 {
    text-align: center;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  input {
    width: 470px;
    color: $text;
    font-family: $font-main;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    padding: 12px 35px;
    border-radius: 5px;
    border: 0.5px solid $accent;
    margin-bottom: 20px;
    @include transition;
    @media (max-width: 768px) {
      width: 100%;
    }
    &::placeholder {
      font-style: italic;
      color: #565656;
    }
    &:hover {
      outline: 1px solid $accent;
    }
    &:focus {
      outline: 1px solid $accent;
    }
  }
  textarea {
    width: 470px;
    height: 171px;
    color: $text;
    font-family: $font-main;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    padding: 12px 35px;
    border-radius: 5px;
    border: 0.5px solid $accent;
    resize: none;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      width: 100%;
      height: 120px;
    }
    &::placeholder {
      font-style: italic;
      color: #565656;
    }
    &:hover {
      outline: 1px solid $accent;
    }
    &:focus {
      outline: 1px solid $accent;
    }
  }
  button {
    width: 300px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
