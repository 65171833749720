.lash-wrap {
  padding: 47px 0 100px 0;
  margin-bottom: 64px;
  @media (max-width: 991px) {
    padding: 47px 0 80px 0;
  }
  @media (max-width: 768px) {
    padding: 35px 0 80px 0;
    margin-bottom: 35px;
  }
  @media (max-width: 576px) {
    padding: 35px 0 70px 0;
  }
}
.lash-wrap.second {
  border-bottom: 1px solid $accent;
}

.lash {
  @include flex(row, flex-start, flex-start);
  gap: 167px;
  @media (max-width: 1200px) {
    gap: 66px;
  }
  @media (max-width: 991px) {
    gap: 24px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  &__card {
    width: 400px;
    @include flex(column, flex-start, flex-start);
    gap: 29px;
    @media (max-width: 991px) {
      width: 321px;
    }
    @media (max-width: 768px) {
      width: 430px;
      gap: 20px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
    label {
      color: $accent;
      font-family: $font-main;
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0%;
      text-align: left;
      margin-bottom: -13px;
    }
    img {
      width: 100%;
      height: 380px;
      object-fit: cover;
      border-radius: 5px;
    }
    p {
      line-height: 150%;
      @media (max-width: 991px) {
        height: 84px;
      }
      @media (max-width: 768px) {
        height: auto;
      }
    }
    &-bottom {
      @include flex(row, flex-start, flex-start);
      gap: 67px;
      width: 100%;
      @media (max-width: 991px) {
        gap: 30px;
      }
      &--right {
        @include flex(column, flex-start, flex-end);
        gap: 20px;
        p {
          @include flex(row, flex-end, center);
          gap: 15px;
          color: $accent;
          font-family: $font-main;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0%;
          height: auto !important;
        }
      }
    }
    span {
      color: $accent;
      font-size: 25px;
      font-weight: 400;
    }
    button {
      width: 270px;
    }
  }
}
.lash__card.second {
  @media (max-width: 991px) {
    width: 300px;
  }
  img {
    width: 400px;
    height: 535px;
    @media (max-width: 991px) {
      width: 300px;
      height: 439px;
    }
  }
  p {
    height: 126px;
    @media (max-width: 991px) {
      height: 168px;
    }
  }
  button {
    width: 100%;
  }
  .lash__card-bottom {
    height: 63px;
    @media (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
}
