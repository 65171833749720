/* Базові стилі, змінні, міксини ти шрифти */
@import "./base/fonts";
@import "./base/reset";
@import "./base/vars";
@import "./base/mixins";
@import "./base/base";
@import "./base/utils";
@import "./base/container";
@import "./base/scroll";

/* Блоки */
@import "blocks/_about.scss";
@import "blocks/_bundle.scss";
@import "blocks/_consultation.scss";
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "blocks/_hero.scss";
@import "blocks/_lash.scss";
@import "blocks/_modal.scss";
@import "blocks/_portfolio.scss";
@import "blocks/_referal.scss";
@import "blocks/_sale.scss";
@import "blocks/_sets.scss";
@import "blocks/_team.scss";
@import "blocks/_testimonials.scss";
