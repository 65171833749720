// Кольори
$white: #fff;
$black: #000;
$text: #323232;
$accent: #907bb3;
$header: #7e6a9c;

// Шрифти
$font-main: "Montserrat", sans-serif;
$font-secondary: "Roboto", sans-serif;

// Інше
$shadow: 0px 4px 15px 0px rgba(54, 33, 86, 0.2);
