.set-wrap {
  padding: 50px 0 100px 0;
  margin-bottom: 100px;
  border-bottom: 1px solid $accent;
  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }
  @media (max-width: 576px) {
    padding: 30px 0;
    margin-bottom: 70px;
  }
}

.set {
  &__title {
    margin-bottom: 25px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  &__text {
    width: 670px;
    line-height: 150%;
    margin-bottom: 45px;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  &__list {
    @include flex(row, flex-start, flex-start);
    gap: 30px;
    @media (max-width: 1200px) {
      gap: 21px;
    }
    @media (max-width: 991px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 70px;
    }
    @media (max-width: 768px) {
      gap: 30px;
    }
    &-item {
      width: 270px;
      @media (max-width: 1200px) {
        width: 250px;
      }
      @media (max-width: 991px) {
        width: 300px;
      }
      h5 {
        color: $accent;
        font-size: 22px;
        font-weight: 600;
        white-space: nowrap;
        margin-bottom: 20px;
      }
      img {
        width: 270px;
        height: 220px;
        object-fit: cover;
        border-radius: 5px;
        margin-bottom: 17px;
        @media (max-width: 1200px) {
          width: 250px;
        }
        @media (max-width: 991px) {
          width: 300px;
          height: 264px;
        }
      }
      .description {
        line-height: 150%;
        height: 105px;
        margin-bottom: 15px;
      }
      .description.variant-2 {
        height: 126px;
        @media (max-width: 991px) {
          height: 147px;
        }
      }
      .price-wrap {
        @include flex(row, flex-start, center);
        gap: 68px;
        margin-bottom: 20px;
      }
      .touch-price {
        @include flex(row, flex-start, baseline);
        gap: 7px;
        span {
          color: $accent;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0%;
        }
        p {
          color: $accent;
          font-family: $font-main;
          font-size: 25px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0%;
          text-align: left;
        }
      }
      .price {
        @include flex(row, flex-start, flex-end);
        gap: 13px;
      }
      .old-price {
        color: $accent;
        font-size: 18px;
        font-weight: 300;
        text-decoration-line: line-through;
      }
      .new-price {
        color: $accent;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      button {
        width: 100%;
        @media (max-width: 991px) {
          width: 250px;
        }
      }
    }
  }
}
.variant-services {
  width: 370px;
  @media (max-width: 1400px) {
    width: 330px;
  }
  @media (max-width: 991px) {
    width: 300px;
  }
  img {
    width: 370px;
    height: 220px;
    @media (max-width: 1400px) {
      width: 330px;
    }
    @media (max-width: 991px) {
      width: 300px;
    }
  }
  button {
    width: 100%;
  }
}
