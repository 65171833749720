.header-wrap {
  position: relative;
  width: 100%;
  height: 83px;
  background: $header;
  box-shadow: $shadow;
  margin-bottom: 61px;
  @media (max-width: 1200px) {
    margin-bottom: 81px;
  }
  @media (max-width: 991px) {
    margin-bottom: 71px;
  }
  @media (max-width: 576px) {
    margin-bottom: 20px;
  }
}
.header {
  @include flex(row, space-between, flex-start);
  &__logo {
    width: 110px;
    height: 110px;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 98px;
      height: 98px;
    }
  }
  &__nav {
    position: relative;
    @include flex(row, center, center);
    gap: 55px;
    padding: 31px 0 32px 0;
    @media (max-width: 1200px) {
      gap: 35px;
    }
    @media (max-width: 991px) {
      position: absolute;
      top: -345px;
      right: 0;
      @include flex(column, center, center);
      gap: 20px;
      width: 357px;
      height: 342px;
      border-radius: 0px 0px 0px 5px;
      background: $white;
      box-shadow: $shadow;
      padding: 0;
      @include transition;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
    &-item {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      @media (max-width: 991px) {
        color: $accent;
      }
    }
    &-item.active {
      font-weight: 700;
    }
    &-close {
      display: none;
      @media (max-width: 991px) {
        @include flex(row, center, center);
        position: absolute;
        top: 31px;
        right: 17px;
      }
    }
  }
  &__box {
    @include flex(row, center, center);
    gap: 30px;
    padding: 31px 0 32px 0;
    &-cart {
      @include flex(row, center, center);
      gap: 13px;
      color: $white;
      font-size: 16px;
      font-weight: 400;
      span {
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
    &-input {
      position: relative;
      @include flex(row, center, center);
      input {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -5px;
        width: 20px;
        padding: 7px 30px 7px 10px;
        font-size: 16px;
        font-weight: 400;
        font-family: $font-main;
        background-color: transparent;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        @include transition;
      }
      input:focus {
        width: 200px;
        background-color: $white;
        box-shadow: $shadow;
      }
      button {
        margin-bottom: -20px;
        transform: translateY(-50%);
        @include flex(row, center, center);
        pointer-events: none;
      }
    }
    &-burger {
      display: none;
      @media (max-width: 991px) {
        @include flex(row, center, center);
      }
    }
  }
}
