@media (min-width: 992px) {
  /* Стилі для скроллу в Chrome та Safari */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $accent;
    border-radius: 6px;
    max-height: 350px;
  }
  ::-webkit-scrollbar-track {
    background-color: #dbdbdb;
  }

  /* Стилі для скроллу в Mozilla Firefox */
  /* Ширина і висота скроллбара */
  * {
    scrollbar-width: thin;
    scrollbar-color: #dbdbdb;
  }

  /* Стилі для кільця вокруг пальця при торканні */
  *::-webkit-scrollbar-thumb:active {
    background-color: $accent;
  }

  /* Стилі для фону скроллбара */
  *::-webkit-scrollbar-track {
    background-color: #dbdbdb;
  }
}
