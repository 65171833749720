.team-wrap {
  margin-bottom: 100px;
  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }
  @media (max-width: 576px) {
    margin-bottom: 70px;
  }
}

.team-title {
  text-align: center;
  margin-bottom: 45px;
}

.team {
  @include flex(row, center, flex-start);
  gap: 30px;
  @media (max-width: 768px) {
    @include flex(column, center, center);
    gap: 45px;
  }
  &__card {
    img {
      height: 480px;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: $shadow;
      margin-bottom: 33px;
      @media (max-width: 768px) {
        height: 380px;
      }
    }
    h5 {
      color: $accent;
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0%;
      margin-bottom: 20px;
    }
    p {
      color: $black;
      font-style: italic;
      font-size: 14px;
      font-weight: 300;
      line-height: 150%;
      letter-spacing: 0%;
      padding-right: 40px;
      margin-bottom: 20px;
    }
    a {
      color: $black;
      font-family: $font-secondary;
      font-size: 14px;
      font-weight: 300;
      line-height: 150%;
      letter-spacing: 0%;
      text-align: left;
      text-decoration-line: underline;
      text-transform: uppercase;
    }
  }
}
