.about-wrap {
  margin-bottom: 100px;
  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }
  @media (max-width: 576px) {
    margin-bottom: 70px;
  }
}
.about {
  h2 {
    text-align: center;
    margin-bottom: 48px;
    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }
    @media (max-width: 991px) {
      margin-bottom: 35px;
    }
    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }
  &__list {
    @include flex(row, center, center);
    gap: 30px;
    @media (max-width: 1200px) {
      gap: 16px;
      flex-wrap: wrap;
    }
    @media (max-width: 991px) {
      gap: 28px;
    }
    @media (max-width: 768px) {
      gap: 10px;
    }
    @media (max-width: 576px) {
      gap: 15px;
    }
    &-item {
      @include flex(column, flex-start, flex-start);
      width: 270px;
      height: 313px;
      padding: 24px 22px 32px 22px;
      border-radius: 10px;
      background: $accent;
      box-shadow: $shadow;
      @media (max-width: 1200px) {
        @include flex(row, flex-start, flex-start);
        gap: 27px;
        width: 427px;
        height: 190px;
        padding: 33px 57px 18px 30px;
      }
      @media (max-width: 991px) {
        @include flex(column, flex-start, flex-start);
        width: 321px;
        height: 286px;
        padding: 24px 73px 26px 22px;
        gap: 0;
      }
      @media (max-width: 768px) {
        @include flex(row, flex-start, flex-start);
        width: 100%;
        height: 170px;
        padding: 33px 90px 33px 40px;
        gap: 27px;
      }
      @media (max-width: 576px) {
        @include flex(column, flex-start, flex-start);
        width: 100%;
        height: 286px;
        padding: 24px 25px 26px 22px;
        gap: 0;
      }
      img {
        margin-bottom: 24px;
        @media (max-width: 1200px) {
          margin-bottom: 0;
        }
        @media (max-width: 991px) {
          margin-bottom: 24px;
        }
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
        @media (max-width: 576px) {
          margin-bottom: 24px;
        }
      }
      h6 {
        color: $white;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 14px;
        @media (max-width: 1200px) {
          margin-bottom: 17px;
        }
        @media (max-width: 991px) {
          margin-bottom: 14px;
        }
        @media (max-width: 768px) {
          margin-bottom: 11px;
        }
        @media (max-width: 576px) {
          margin-bottom: 14px;
        }
      }
      p {
        color: $white;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
.services-variant {
  height: 374px;
  @media (max-width: 1200px) {
    height: 235px;
  }
  @media (max-width: 991px) {
    height: 360px;
  }
  @media (max-width: 768px) {
    height: 240px;
  }
  @media (max-width: 576px) {
    height: 340px;
  }
}
