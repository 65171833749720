.none {
  display: none;
}
.no-scroll {
  overflow-y: hidden;
}

.open-menu {
  top: 0 !important;
}
