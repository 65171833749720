/* Базові стилі */

/* Загальні налаштування */
* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  height: 100vh;
  font-style: normal;
}

body {
  background: $white;
  color: $black;
  font-family: $font-main;
  font-style: normal;
  line-height: normal;
  font-size: 14px;
  font-weight: 300;
}

h2 {
  color: $accent;
  font-family: $font-main;
  font-size: 35px;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
p {
  font-family: $font-secondary;
}
button {
  font-family: $font-main;
  cursor: pointer;
}
.primary {
  @include flex(row, center, center);
  gap: 10px;
  color: $white;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
  text-transform: uppercase;
  padding: 17px 30px 18px 32px;
  border-radius: 10px;
  background: $accent;
  box-shadow: $shadow;
  border: 1px solid $accent;
  @include transition;
  @media (max-width: 1200px) {
    gap: 60px;
  }
  &:hover {
    color: $accent;
    background: $white;
    border: 1px solid $white;
  }
  &:active {
    font-weight: 700;
  }
}
.outline {
  @include flex(row, center, center);
  gap: 10px;
  color: $accent;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
  text-transform: uppercase;
  padding: 17px 30px 18px 32px;
  border-radius: 10px;
  background: $white;
  box-shadow: $shadow;
  border: 1px solid $accent;
  @include transition;
  &__phone-dark {
    display: block;
    opacity: 1;
    @include transition;
  }
  &__phone-light {
    display: none;
    opacity: 0;
    @include transition;
  }
  &:hover {
    color: $white;
    background: $accent;
    .outline__phone-dark {
      display: none;
      opacity: 0;
    }
    .outline__phone-light {
      display: block;
      opacity: 1;
    }
  }
  &:active {
    font-weight: 700;
  }
}
