.consultation-wrap {
  margin-bottom: 100px;
  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }
  @media (max-width: 576px) {
    margin-bottom: 70px;
  }
}
.consultation {
  @include flex(row, flex-start, center);
  gap: 50px;
  @media (max-width: 991px) {
    @include flex(column, center, center);
    gap: 25px;
  }
  img {
    width: 500px;
    height: 296px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: $shadow;
    @media (max-width: 991px) {
      width: 100%;
      height: 363px;
    }
  }
  &__info {
    width: 489px;
    @media (max-width: 1200px) {
      width: 300px;
    }
    @media (max-width: 991px) {
      width: 100%;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 150%;
      letter-spacing: 0%;
      margin-bottom: 35px;
      @media (max-width: 1200px) {
        text-align: center;
      }
    }
    p > b {
      color: $accent;
    }
    button {
      width: 300px;
      @media (max-width: 991px) {
        margin: auto;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
