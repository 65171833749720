.hero-wrap {
  margin-bottom: 100px;
  @media (max-width: 1200px) {
    margin-bottom: 80px;
  }
  @media (max-width: 576px) {
    margin-bottom: 70px;
  }
}
.hero {
  @include flex(row, space-between, center);
  gap: 24px;
  @media (max-width: 1200px) {
    gap: 34px;
  }
  @media (max-width: 991px) {
    gap: 53px;
  }
  @media (max-width: 768px) {
    @include flex(column, center, center);
    gap: 24px;
  }
  @media (max-width: 576px) {
    gap: 20px;
  }
  &__info {
    width: 406px;
    @media (max-width: 991px) {
      width: 317px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    h1 {
      color: $accent;
      font-size: 55px;
      font-weight: 600;
      line-height: 130%;
      text-transform: uppercase;
      @media (max-width: 991px) {
        font-size: 45px;
      }
      @media (max-width: 768px) {
        margin-bottom: 6px;
      }
    }
    h4 {
      color: $text;
      font-size: 35px;
      line-height: 130%;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        font-size: 25px;
        margin-bottom: 22px;
      }
    }
    p {
      color: $text;
      font-size: 14px;
      font-weight: 300;
      line-height: 150%;
      a {
        display: inline-block;
        color: $accent;
        font-weight: 700;
      }
    }
    p.secondary {
      margin-top: 15px;
      @media (max-width: 991px) {
        margin-top: 10px;
      }
    }
    button {
      margin-top: 30px;
      width: 300px;
      @media (max-width: 576px) {
        margin-top: 25px;
        width: 100%;
      }
    }
  }
  &__img {
    width: 639px;
    height: 411px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: $shadow;
    @media (max-width: 1200px) {
      width: 445px;
      height: 362px;
    }
    @media (max-width: 991px) {
      width: 300px;
      height: 307px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
